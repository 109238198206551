import {inject, Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private http = inject(HttpClient);

  getUserData(): Observable<User> {
    return this.http.get<User>(`${environment.supraApi}/v1/user/me`);
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(`${environment.supraApi}/v1/user/me/roles`);
  }
}
