import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  private http = inject(HttpClient);

  getUserPermissions(): Observable<[string]> {
    return this.http.get<[string]>(`${environment.supraApi}/v1/user/me/permissions`);
  }
}
